import { GetServerSideProps, GetServerSidePropsContext } from "next";
// import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { Partner } from "~/models/partners";
import { Category, Tag as ITag } from "~/models/category";
import { Promotion } from '~/models/promotions';

import { deafultComponent } from '~/helpers/dynamicComponent';
import { getDeviceType } from '~/helpers/getDeviceType';
import { getCategories, getPromotions, getTags } from "~/plugins/api";
import { getPartners, getPopularPartners, IPartnersResponse } from '~/plugins/api/partner';
import getParsedCookieWithServer from '~/helpers/getParsedCookieWithServer';
import { locationCookie } from '~/constants';
import { UserAddressToken } from '~/models';

const HomeDesktop = dynamic(() =>
  deafultComponent(import('~/components/_DesktopUI/_Pages/Home'))
);

const HomeMobile = dynamic(() =>
  deafultComponent(import('~/components/_MobileUI/_Pages/Home'))
);

interface HomeProps extends Record<string, unknown> {
  categories: Category[];
  tags: ITag[];
  title: string;
  partners: IPartnersResponse;
  promotions: Promotion[] | [];
  popularPartners: Partner[] | [];
  isDesktop: boolean;
  // currentCategoryId: string
}

const Home = ({
  categories,
  tags,
  title,
  partners,
  promotions = [],
  popularPartners = [],
  isDesktop,
  // currentCategoryId,
}: HomeProps): JSX.Element => {
  // const { query, push } = useRouter();
  // const filter = (query?.filter as string) ?? "";
  // const categoryId = (query.categoryId as string) ?? "";

  // if (push && !categoryId) {
  //   push(
  //     `/?categoryId=${currentCategoryId}${filter.length ? `&filter=${filter}` : ''} `,
  //     `/?categoryId=${currentCategoryId}${filter.length ? `&filter=${filter}` : ''} `,
  //     { shallow: true }
  //   )
  // }

  const props = {
    categories,
    tags,
    title,
    partners,
    promotions,
    popularPartners,
  };

  return !isDesktop ? (
    <HomeMobile {...props} />
  ) : (
    <HomeDesktop {...props} />
  );
};

export const getServerSideProps: GetServerSideProps<HomeProps> = async (
  context: GetServerSidePropsContext
) => {
  const location = getParsedCookieWithServer<UserAddressToken>(context.req, locationCookie);
  const queryCategoryId = (context?.query?.categoryId as string) ?? "1";
  const filter = (context?.query?.filter as string) ?? "";
  const userAgent = context?.req?.headers["user-agent"] as string;
  const isDesktop = getDeviceType(userAgent).isDesktop;
  // const isTablet = getDeviceType(userAgent).isTablet;
  // const isMobile = getDeviceType(userAgent).isMobile;
  // const limit = isDesktop ? 48 : isTablet ? 24 : 12;

  const categories = await getCategories(location?.cityId || undefined);
  const currentCategory = categories.filter((c: Category) => +c.id == +queryCategoryId)[0] ?? categories[0];

  const categoryTags = await getTags({
    marketCategoryId: currentCategory.id,
    latitude: location?.latitude,
    longitude: location?.longitude,
  });

  const categoryPartners = await getPartners({
    marketCategoryId: currentCategory.id,
    tagId: categoryTags?.tags.filter(({ name }) => name === filter)[0]?.id ?? null,
    latitude: location?.latitude,
    longitude: location?.longitude,
    // limit: limit,
    // offset: 0,
  });

  const promotions = await getPromotions({
    marketCategoryId: null,
    partnerId: null,
    latitude: location?.latitude,
    longitude: location?.longitude,
  });

  const popularPartners = await getPopularPartners({
    latitude: location?.latitude,
    longitude: location?.longitude,
  });

  const filterFullfilled = (arr: PromiseSettledResult<any>[]) => {
    return (
      arr
        .filter((r) => r.status === "fulfilled")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((p) => (p as PromiseFulfilledResult<any>).value)
    );
  };

  const requests = [categories, categoryTags, categoryPartners, promotions, popularPartners];
  const requestsPromises = await Promise.allSettled(requests);
  // const [requestsPromisesSettled] = filterFillfilled(
  //   await Promise.allSettled([requestsPromises])
  // );
  const result = filterFullfilled(requestsPromises);

  return {
    props: {
      categories: result[0],
      tags: result[1].tags,
      title: currentCategory.name,
      partners: result[2],
      promotions: result[3].banners,
      popularPartners: result[4],
      isDesktop: isDesktop,
      // currentCategoryId: String(currentCategory.id),
    }
  };
};

export default Home;


  // if (!categoryId) {
  //   return {
  //     props: {},
  //     redirect: {
  //       destination: `/?categoryId=1`,
  //       permanent: false,
  //     },
  //   };
  // }